.header {
  padding: 1.25rem 0;
  font-weight: 500;
  position: sticky;
  background: $light;
  z-index: 99;
  top: 0;
  opacity: 0;
  transform: translateY(-1.5rem);
  transition:
    opacity 0.25s linear,
    transform 0.25s ease-in-out,
    padding 0.25s ease-in-out;

  .lang-menu {
    display: flex;
    @include breakpoint(custom) {
      display: none;
    }
    padding-left: 1rem;
    > ul {
      list-style: none;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      display: flex;
      transition: transform 0.15s ease-in-out;
      justify-content: flex-end;

      // @include breakpoint(custom) {
      //   background: $light;
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 428px;
      //   max-width: 100%;
      //   height: 100%;
      //   flex-direction: column;
      //   justify-content: flex-start;
      //   transform: translateX(-100%);
      //   pointer-events: all;
      //   padding: 1.25rem 6vw;
      //   overflow: auto;
      // }

      // @include breakpoint(xs) {
      //   padding: 1rem 6vw;
      // }

      // &::before {
      //   @include breakpoint(custom) {
      //     content: 'Menù';
      //     order: -2;
      //     font-weight: 700;
      //     font-size: 1.2rem;
      //     line-height: 2.4rem;
      //     margin: 0.1rem 0 1rem;
      //   }

      //   @include breakpoint(custom) {
      //     line-height: 2rem;
      //   }

      //   @include breakpoint(xs) {
      //     line-height: 1.8rem;
      //   }
      // }

      > li {
        padding: 0;
        white-space: nowrap;
        position: relative;

        // @include breakpoint(custom) {
        //   margin: 0 -6vw;
        // }

        a:not([class]) {
          font-size: 0.8rem;
          // padding: 0.5em 0.9em 0.6em;
          display: inline-flex;
          align-items: center;
          position: relative;
          border-radius: 0.65rem;

          @include breakpoint(xl) {
            padding: 0.5em 0.7rem 0.6em;
          }

          &:hover {
            color: $blue;
            background: none !important;
          }
        }

        &.menu-item-has-children {
          > a {
            position: relative;

            &::after {
              @extend .icon;
              content: '\f31a';
              margin-left: 0.2em;
              font-size: 1rem;
            }
          }

          &:hover {
            .sub-menu {
              display: flex;

              &.col-2 {
                display: flex;
                flex-direction: row;
                gap: 16px;
                width: 466px;
                .wrap_submenu_container {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  flex: 1;
                  .label {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    padding: 5px 16px;
                  }
                  .wrap_submenu_wrapper {
                    li {
                      white-space: wrap;
                    }
                  }
                }
              }
            }

            // > a {
            //   color: $blue;
            // background: transparentize($blue, 0.95);

            // &::before {
            //   content: '';
            //   width: 150%;
            //   left: -25%;
            //   height: 3rem;
            //   top: 5px;
            //   position: absolute;
            //   display: block;
            //   transform: perspective(36px) rotateX(73deg);
            // }
            // }
          }
        }

        // &.first {
        //   @include breakpoint(lg) {
        //     order: -1;
        //   }

        //   .avatar-wrap {
        //     margin-top: 0.8rem;
        //     margin-bottom: 1rem;
        //   }
        // }

        // &.open {
        //   @include breakpoint(custom) {
        //     .sub-menu {
        //       display: flex;
        //     }

        //     > a {
        //       color: $blue;
        //       background: transparentize($blue, 0.95);
        //     }
        //   }
        // }

        .sub-menu {
          display: none;
          list-style: none;
          justify-content: center;

          position: absolute;
          top: 100%;
          left: -176px;
          background: $light;
          border-top: 1px solid transparentize($dark, 0.9);
          flex-direction: column;
          width: 215px;
          padding: 0.8rem;
          box-shadow: 0 0.2rem 0.8rem 0 #00000026;
          border-radius: 0.8rem;

          // @include breakpoint(custom) {
          //   flex-direction: column;
          //   padding-left: calc(6vw + 1rem);
          //   padding-top: 0.4rem;
          // }

          li {
            padding: 0.2rem 0;

            @include breakpoint(custom) {
              padding: 0;
            }

            &:last-child {
              padding-bottom: 0;
            }

            &:first-child {
              padding-top: 0;
            }

            a {
              display: flex;
              flex-direction: column;
              text-align: left;
              align-items: flex-start;
              font-size: 0.8rem;
              padding: 0.6rem 0.8rem;

              @include breakpoint(custom) {
                display: block;
                padding: 0.6rem 0;
                text-align: left;
                font-size: 1rem;
              }

              @include breakpoint(xxs) {
                font-size: 0.9rem;
              }

              &:hover {
                background: transparentize($blue, 0.95);

                @include breakpoint(custom) {
                  background: none;
                }
              }

              &.logo-menu {
                font-size: 0;
                padding: 0.6rem 0.8rem;
                border-radius: 0.75rem;

                @include breakpoint(custom) {
                  border-radius: 0;
                  padding: 0.6rem 0;
                }

                img {
                  order: -1;
                  height: 1.6rem;
                  width: auto;
                  margin-bottom: 6px;

                  @include breakpoint(custom) {
                    margin-bottom: 0;
                    height: 1.8rem;
                  }
                }
              }
            }

            small {
              display: block;
              font-size: 0.6rem;

              @include breakpoint(custom) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .lang-menu-mobile {
    display: none;
    @include breakpoint(custom) {
      display: flex;
      padding-left: 0;
      flex-direction: column;
      position: fixed;
      background-color: $light;
      z-index: 1;
      width: 428px;
      left: 0;
      margin: 0 !important;
    }

    & > a:first-child {
      font-size: 24px;
      line-height: 34px;
      font-weight: 400;
      // padding: 16px 1.8vw;
      padding-left: 20px;
      & > span {
        display: flex;
        gap: 8px;
        align-items: center;
        position: relative;
        padding: 8px 0;

        &::after {
          @extend .icon;
          content: '\f31a';
          margin-left: 0.2em;
          font-size: 1rem;
        }
      }

      &:hover {
        background: transparent;
      }
    }

    &.expanded {
      // position: relative;
      .sub-menu {
        display: flex;
        max-width: 215px;
        padding: 16px;
        border-radius: 15px;
        position: fixed;
        top: 72px;
        z-index: 1;
        left: 16px;
        background-color: $light;
        box-shadow: 0 0.2rem 0.8rem 0 #00000026;
      }
    }

    > ul {
      list-style: none;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      display: flex;
      transition: transform 0.15s ease-in-out;
      justify-content: flex-end;

      > li {
        padding: 0;
        white-space: nowrap;
        position: relative;

        a:not([class]) {
          font-size: 0.8rem;
          display: inline-flex;
          align-items: center;
          position: relative;
          border-radius: 0.65rem;

          @include breakpoint(xl) {
            // padding: 0.5em 0.7rem 0.6em;
            // padding: 0.5em 1.8vw 0.6em;
            padding: 0.5em 6vw 0.6em;
          }

          &:hover {
            color: $blue;
            background: none !important;
          }
        }

        &.menu-item-has-children {
          > a {
            position: relative;

            &::after {
              @extend .icon;
              content: '\f31a';
              margin-left: 0.2em;
              font-size: 1rem;
            }
          }

          &:hover {
            .sub-menu {
              display: flex;
            }
          }
        }

        .sub-menu {
          display: none;
          list-style: none;
          justify-content: center;

          position: absolute;
          top: 100%;
          left: -225px;
          background: $light;
          border-top: 1px solid transparentize($dark, 0.9);
          flex-direction: column;
          width: 215px;
          padding: 0.8rem;
          box-shadow: 0 0.2rem 0.8rem 0 #00000026;
          border-radius: 0.8rem;

          li {
            padding: 0.2rem 0;

            @include breakpoint(custom) {
              padding: 0;
            }

            &:last-child {
              padding-bottom: 0;
            }

            &:first-child {
              padding-top: 0;
            }

            a {
              display: flex;
              flex-direction: column;
              text-align: left;
              align-items: flex-start;
              font-size: 0.8rem;
              padding: 0.6rem 0.8rem;

              @include breakpoint(custom) {
                display: block;
                padding: 0.6rem 0;
                text-align: left;
                font-size: 1rem;
              }

              @include breakpoint(xxs) {
                font-size: 0.9rem;
              }

              &:hover {
                background: transparentize($blue, 0.95);

                @include breakpoint(custom) {
                  background: none;
                }
              }

              &.logo-menu {
                font-size: 0;
                padding: 0.6rem 0.8rem;
                border-radius: 0.75rem;

                @include breakpoint(custom) {
                  border-radius: 0;
                  padding: 0.6rem 0;
                }

                img {
                  order: -1;
                  height: 1.6rem;
                  width: auto;
                  margin-bottom: 6px;

                  @include breakpoint(custom) {
                    margin-bottom: 0;
                    height: 1.8rem;
                  }
                }
              }
            }

            small {
              display: block;
              font-size: 0.6rem;

              @include breakpoint(custom) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .wrap {
    align-items: center;
  }

  a {
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  @include breakpoint(xs) {
    padding: 1rem 0;
  }

  &__logo {
    position: relative;
    line-height: 0;

    &::after {
      background: url('../../images/logo.svg');
    }

    svg,
    img {
      height: 2.4rem;
      width: auto;
      margin: 0.1rem 0;

      @include breakpoint(custom) {
        height: 2rem;
      }

      @include breakpoint(xs) {
        height: 1.8rem;
      }
    }
  }

  &__menu {
    flex-grow: 1;

    @include breakpoint(custom) {
      background-color: transparentize($dark, 1);
      position: fixed;
      right: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      pointer-events: none;
      transition: background-color 0.15s linear;
    }

    > ul {
      list-style: none;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      display: flex;
      transition: transform 0.15s ease-in-out;
      justify-content: flex-end;

      @include breakpoint(custom) {
        background: $light;
        position: absolute;
        left: 0;
        top: 0;
        width: 428px;
        max-width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        transform: translateX(-100%);
        pointer-events: all;
        // padding: 0 6vw 1.25rem 6vw;
        padding: 0 6vw 1.25rem;
        overflow: auto;
      }

      @include breakpoint(xs) {
        // padding: 1rem 6vw;
        // padding: 0 6vw 1rem 6vw;
        padding: 0 1rem 24px;
      }

      // &::before {
      //   @include breakpoint(custom) {
      //     content: 'Menù';
      //     order: -2;
      //     font-weight: 700;
      //     font-size: 1.2rem;
      //     line-height: 2.4rem;
      //     margin: 0.1rem 0 1rem;
      //   }

      //   @include breakpoint(custom) {
      //     line-height: 2rem;
      //   }

      //   @include breakpoint(xs) {
      //     line-height: 1.8rem;
      //   }
      // }

      > li {
        padding: 0;
        white-space: wrap;
        position: relative;

        @include breakpoint(custom) {
          margin: 0 -6vw;
        }

        &:nth-of-type(2) {
          margin-top: 82px !important;

          @include breakpoint(custom, true) {
            margin-top: 0 !important;
          }
        }

        a:not([class]) {
          font-size: 0.8rem;
          padding: 0.5em 0.9em 0.6em;
          display: inline-flex;
          align-items: center;
          position: relative;
          border-radius: 0.65rem;

          // @include breakpoint(xl) {
          //   padding: 0.5em 0.7rem 0.6em;
          // }

          @include breakpoint(custom) {
            font-size: 1.2rem;
            margin: 0;
            display: flex;
            border-radius: 0;
            padding: 0.5em 6vw 0.6em;
            // padding: 0.5em 1.8vw 0.6em;
          }

          @include breakpoint(xxs) {
            font-size: 1rem;
          }

          &:hover {
            color: $blue;
            background: transparentize($blue, 0.95);
          }
        }

        a.cta,
        .avatar-wrap {
          @include breakpoint(custom) {
            // margin: 2rem 6vw;
            margin: 1rem 6vw 0;
          }
        }

        a.cta {
          @include breakpoint(custom) {
            width: auto;
          }
        }

        &.menu-item-has-children {
          > a {
            position: relative;

            &::after {
              @extend .icon;

              content: '\f31a';
              margin-left: 0.2em;
              font-size: 1rem;
            }
          }

          &:hover {
            @include breakpoint(custom, true) {
              .sub-menu {
                display: flex;
                &.col-2 {
                  display: flex;
                  flex-direction: row;
                  gap: 16px;
                  width: 466px;
                  .wrap-submenu-container {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    flex: 1;
                    .label {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 26px;
                      padding: 5px 16px;
                    }
                    li {
                      white-space: wrap;
                    }
                  }
                }
              }

              > a {
                color: $blue;
                background: transparentize($blue, 0.95);

                &::before {
                  content: '';
                  width: 150%;
                  left: -25%;
                  height: 3rem;
                  top: 5px;
                  position: absolute;
                  display: block;
                  transform: perspective(36px) rotateX(73deg);
                }
              }
            }
          }
        }

        &.first {
          @include breakpoint(lg) {
            order: -1;
          }

          .avatar-wrap {
            margin-top: 0.8rem;
            margin-bottom: 1rem;
          }
        }

        &.open {
          @include breakpoint(xs, false) {
            .sub-menu {
              padding: 24px;
            }
          }
          @include breakpoint(custom) {
            .sub-menu {
              display: flex;

              .wrap-submenu-container {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .label {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 26px;
                  padding: 5px 0;
                }
              }

              &.col-2 {
                gap: 36px;
              }
            }

            > a {
              color: $blue;
              background: transparentize($blue, 0.95);
              line-height: 34px;
            }
            span,
            li {
              font-size: 16px;
              line-height: 26px;
            }
            span.label {
              font-weight: 600;
              padding: 5px 0;
            }
          }
        }

        .sub-menu {
          display: none;
          list-style: none;
          justify-content: center;

          @include breakpoint(custom, true) {
            position: absolute;
            top: 100%;
            left: 0;
            background: $light;
            border-top: 1px solid transparentize($dark, 0.9);
            flex-direction: column;
            width: 286px;
            padding: 0.8rem;
            box-shadow: 0 0.2rem 0.8rem 0 #00000026;
            border-radius: 0.8rem;
          }

          @include breakpoint(custom) {
            flex-direction: column;
            padding-left: calc(6vw + 1rem);
            padding-top: 0.4rem;
          }

          li {
            padding: 0.2rem 0;

            @include breakpoint(custom) {
              padding: 0;
            }

            &:last-child {
              padding-bottom: 0;
            }

            &:first-child {
              padding-top: 0;
            }

            a {
              display: flex;
              flex-direction: column;
              text-align: left;
              align-items: flex-start;
              font-size: 16px;
              line-height: 26px;
              padding: 0.6rem 0.8rem;

              @include breakpoint(custom) {
                display: block;
                padding: 0.6rem 0;
                text-align: left;
                font-size: 1rem;
              }

              @include breakpoint(xxs) {
                font-size: 16px;
                line-height: 26px;
              }

              &:hover {
                background: transparentize($blue, 0.95);

                @include breakpoint(custom) {
                  background: none;
                }
              }

              &.logo-menu {
                font-size: 0;
                padding: 0.6rem 0.8rem;
                border-radius: 0.75rem;

                @include breakpoint(custom) {
                  border-radius: 0;
                  padding: 0.6rem 0;
                }

                img {
                  order: -1;
                  height: 1.6rem;
                  width: auto;
                  margin-bottom: 6px;

                  @include breakpoint(custom) {
                    margin-bottom: 0;
                    height: 1.8rem;
                  }
                }
              }
            }

            small {
              display: block;
              font-size: 0.6rem;

              @include breakpoint(custom) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &__cart {
    padding-left: 0.9rem;

    @include breakpoint(xl) {
      padding-left: 0.75rem;
    }

    @include breakpoint(custom) {
      padding-left: 0;
    }

    a {
      display: flex;
      align-items: center;

      &:hover {
        color: $blue;
      }

      i {
        font-size: 1.5rem;
        margin-right: 0.2rem;

        @include breakpoint(sm) {
          margin-right: 0;
        }
      }

      span {
        background: $blue;
        line-height: 2;
        text-align: center;
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        font-size: 0.6rem;
        color: $light;
        border-radius: 50%;

        &:empty {
          &::before {
            content: '0';
          }
        }
      }
    }
  }

  &__notification {
    padding-left: 1.6rem;

    @include breakpoint(custom) {
      display: none;
    }

    a {
      position: relative;

      span {
        position: absolute;
        background: $orange;
        display: block;
        width: 0.45rem;
        height: 0.45rem;
        border-radius: 50%;
        top: 2px;
        left: 18px;
        opacity: 0;
      }
    }

    &.active {
      a {
        span {
          opacity: 1;
        }
      }
    }
  }

  &__cart,
  &__notification {
    a {
      display: flex;
      align-items: center;

      &:hover {
        color: $blue;
      }

      i {
        font-size: 1.5rem;
        margin-right: 0.2rem;

        @include breakpoint(sm) {
          margin-right: 0;
        }
      }
    }
  }

  &__user {
    padding-left: 1.8rem;

    @include breakpoint(xl) {
      padding-left: 1.5rem;
    }

    @include breakpoint(custom) {
      display: none;
    }
  }

  &__cta2 {
    padding-left: 16px;

    @include breakpoint(custom) {
      display: none;
    }
  }

  #hamburger {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    top: 40vh;

    + label {
      transition: all 0.15s ease-in-out;
      order: -1;
      user-select: none;
      pointer-events: all;
      cursor: pointer;
      display: none;
      width: 1.4rem;
      height: 1.4rem;
      position: relative;
      z-index: 9;
      flex-shrink: 0;

      @include breakpoint(custom) {
        display: block;
      }

      @include breakpoint(sm) {
        width: 1.1em;
        height: 1.1em;
      }

      &::after {
        @include breakpoint(custom) {
          cursor: context-menu;
          content: '';
          left: calc(100% + 6vw);
          width: calc(100vw - 428px);
          height: 90vh;
          position: fixed;
          top: -2.5rem;
          display: none;
        }
      }

      i,
      i::after,
      i::before {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        transition-timing-function: ease;
        transition-duration: 0.15s;
        transition-property: transform;
        background-color: $blue;
      }

      i {
        top: 12px;
        display: block;
        margin-top: -1px;

        @include breakpoint(custom) {
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-duration: 75ms;
        }

        &::after {
          content: '';
          bottom: -8px;

          @include breakpoint(custom) {
            transition:
              bottom 75ms ease 0.12s,
              transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
          }

          @include breakpoint(sm) {
            bottom: -7px;
          }
        }

        &::before {
          content: '';
          top: -8px;

          @include breakpoint(custom) {
            transition:
              top 75ms ease 0.12s,
              opacity 75ms ease;
          }

          @include breakpoint(sm) {
            top: -7px;
          }
        }
      }
    }

    &:checked {
      + label {
        transform: translateX(min(80vw - 1.4rem, 428px - 12vw - 1.4rem));

        &::after {
          @include breakpoint(custom) {
            display: block;
          }
        }

        i,
        i::after,
        i::before {
          background: $dark;
        }

        i {
          @include breakpoint(custom) {
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            transform: rotate(45deg);
          }

          &::before {
            @include breakpoint(custom) {
              top: 0;
              transition:
                top 75ms ease,
                opacity 75ms ease 0.12s;
              opacity: 0;
              transform: none;
            }
          }

          &::after {
            @include breakpoint(custom) {
              bottom: 0;
              transition:
                bottom 75ms ease,
                transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
              transform: rotate(-90deg);
            }
          }
        }

        + nav {
          @include breakpoint(custom) {
            background-color: transparentize($dark, 0.25);
          }

          ul {
            @include breakpoint(custom) {
              transform: none;
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    }
  }

  &__balance {
    width: 1.4rem;
    height: 1.4rem;
    flex-shrink: 0;
    display: none;

    @include breakpoint(custom) {
      display: block;
    }

    @include breakpoint(sm) {
      width: 1.1em;
      height: 1.1em;
    }

    + .header__user {
      padding-left: 1rem;

      @include breakpoint(xl) {
        padding-left: 0.8rem;
      }
    }
  }

  &--scrolled {
    box-shadow: 0px 4px 8px transparentize($dark, 0.95);
    padding: 0.8rem 0;
  }

  body.loaded & {
    opacity: 1;
    transform: none;
  }
}

@import '@styles/basic';

.langSwitcher {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  user-select: none;
  font-size: 16px;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 8px;
  span {
    padding: 12px 16px;
    margin: 0;
    border-radius: 0.65rem;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    min-height: 50px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    order: -1;

    &:hover {
      color: $blue;
      background: transparentize($blue, 0.95);
    }

    &.isActive {
      background: rgba($blue, 0.05);
      color: $blue;
    }
    img {
      flex-shrink: 0;
      margin-right: 6px;
      border-radius: 1.75px !important;
    }
  }
}

// .langSwitcherContainer {
//   display: flex;
//   justify-content: flex-end !important;
//   margin-bottom: 24px !important;
//   .langSwitcher {
//     text-align: right;
//     user-select: none;
//     font-size: 0.8rem;
//     white-space: nowrap;
//     overflow: auto;
//     -ms-overflow-style: none;
//     scrollbar-width: none;

//     &::-webkit-scrollbar {
//       display: none;
//     }

//     &.expanded {
//       span {
//         display: inline-flex;
//         &:not(:last-child) {
//           margin-right: 0.5em;
//         }
//       }

//       span.isActive {
//         &::after {
//           content: '\f31b';
//           font:
//             normal normal normal 1em/1 Phosphor,
//             sans-serif;
//         }
//       }
//     }

//     span {
//       padding: 0.5em 1em;
//       margin: 0;
//       border-radius: 0.65rem;
//       display: none;
//       align-items: center;
//       flex-shrink: 0;
//       min-height: 40px;
//       cursor: pointer;
//       order: -1;

//       &.isActive {
//         display: inline-flex;
//         background: rgba($blue, 0.05);
//         color: $blue;

//         &::after {
//           content: '\f31c';
//           font:
//             normal normal normal 1em/1 Phosphor,
//             sans-serif;
//         }
//       }
//       img {
//         flex-shrink: 0;
//         margin-right: 0.5em;
//       }
//     }
//   }
// }
